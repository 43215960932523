import React, { useState } from 'react'
import styles from "./cataloage-open.module.scss"
import CatalogCard from "./CatalogCard"
import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from 'react'

const cataloage = graphql`
{
cataloageBaie:allFile(filter:{relativeDirectory:{eq:"cataloage/Baie"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageBucatarie:allFile(filter:{relativeDirectory:{eq:"cataloage/Bucatarie"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageMobila:allFile(filter:{relativeDirectory:{eq:"cataloage/Mobila"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  pardoseli:allFile(filter:{relativeDirectory:{eq:"cataloage/Pardoseli, Gresie si Faianta"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  solutiiTehnice:allFile(filter:{relativeDirectory:{eq:"cataloage/Solutii tehnice"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  cataloageNoi:allFile(filter:{relativeDirectory:{eq:"cataloage/Cataloage2023"}}){
    edges{
      node{
          extension
          publicURL
          name
          childImageSharp{
          fixed(width:200,height:300){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

}
`

const CataloageOpen = () => {

  const formatCataloageArr = (cataloageArr) => {
    const pdfs = cataloageArr.filter(catalog => catalog.node.extension === 'pdf')
    const images = cataloageArr.filter(catalog => catalog.node.extension === 'png')

    const cataloage = pdfs.map(pdf => {
      const myImage = images.filter(image => image.node.name === pdf.node.name)[0]

      return {
        image: myImage ? myImage.node : { childImageSharp: { fixed: null } },
        pdf: myImage ? pdf.node.publicURL : null,
        primary: myImage ? pdf.node.name : null
      }
    })

    return cataloage
  }

  const { cataloageBaie, cataloageBucatarie, cataloageMobila, pardoseli, solutiiTehnice, cataloageNoi } = useStaticQuery(cataloage)

  const [cataloageArr] = useState(() => {
    const cataloageBaieArr = formatCataloageArr(cataloageBaie.edges)

    const cataloageBucatarieArr = formatCataloageArr(cataloageBucatarie.edges)

    const cataloageMobilaArr = formatCataloageArr(cataloageMobila.edges)

    const cataloagePardoseliArr = formatCataloageArr(pardoseli.edges)

    const cataloageTehniceArr = formatCataloageArr(solutiiTehnice.edges)

    const cataloageNoiArr = formatCataloageArr(cataloageNoi.edges)

    return {
      cataloageBaie: cataloageBaieArr,
      cataloageBucatarie: cataloageBucatarieArr,
      cataloageMobila: cataloageMobilaArr,
      cataloagePardoseli: cataloagePardoseliArr,
      cataloageTehnice: cataloageTehniceArr,
      cataloageNoi: cataloageNoiArr
    }
  })

  const [currentPDF, setCurrentPDf] = useState(null)

  useEffect(() => {
    const path = window.location.href
    const pdfParts = path.split('#')[1].split('_')

   
    if (pdfParts[0] === "cataloage-noi") {
      for (let i = 0; i < cataloageArr.cataloageNoi.length; i++) {
        if (cataloageArr.cataloageNoi[i].primary.replace(/[\s]/g, '-').toLowerCase() === pdfParts[1])
          setCurrentPDf(cataloageArr.cataloageNoi[i].pdf)
      }
    }
  }, [currentPDF])

  function changePDF(subtitle, index) {
    

    if (subtitle === "Cataloage noi") {
      setCurrentPDf(cataloageArr.cataloageNoi[index].pdf);
      window.location.hash = 'cataloage-noi_' + cataloageArr.cataloageNoi[index].primary.replace(/[\s]/g, '-').toLowerCase()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.catalogs}>
       

        <div className={styles.section}>
          <div className={styles.section_title}>
            <h2> Cataloage </h2>
          </div>
          <hr />
          {
            cataloageArr.cataloageNoi.map((catalog, index) => (
              <CatalogCard index={index} image={catalog.image.childImageSharp.fixed} title={catalog.primary} subtitle="Cataloage noi"
                pdf={catalog.pdf} onClick={changePDF} />
            ))
          }
        </div>


      </div>
      <div className={styles.pdf__viewer}>
        <iframe title="pdf-viewer" src={currentPDF} className={styles.pdf} />
      </div>
    </div>
  )
}

export default CataloageOpen